<template>
  <v-card elevation="1" class="resumo">
    <div class="pa-4 grey lighten-3 font-weight-bold">
      Resumo da Solicitação
    </div>
    <hr />
    <div class="body pa-4">
      <div class="d-flex justify-start">
        <div class="info-resumo">
          <p class="mb-1 caption">Status da Solicitação:</p>
          <v-chip :color="solicitacao.cor"> {{ solicitacao.status }} </v-chip>
        </div>
        <div class="info-resumo" v-if="solicitacao.statusCartasResp">
          <p class="mb-1 caption">Status da Carta Resposta:</p>
          <v-chip :color="solicitacao.cor">
            {{ solicitacao.statusCartasResp }}
          </v-chip>
        </div>
        <div class="info-resumo" v-if="solicitacao.assunto">
          <p class="mb-1 caption">Motivo/tema:</p>
          <span> {{ solicitacao.assunto }} </span>
        </div>
        <div
          class="info-resumo"
          v-if="solicitacao.mesa.nome === 'Informações científicas'"
        >
          <p class="mb-1 caption">Forma de envio:</p>
          <span v-if="solicitacao.envioEmail">E-mail</span>
          <span v-if="solicitacao.envioCorreio">Correios</span>
        </div>
        <div
          class="info-resumo"
          v-if="
            solicitacao.colaborador !== null &&
            solicitacao.colaborador !== 'N/A'
          "
        >
          <p class="mb-1 caption">Colaborador:</p>
          <span> {{ solicitacao.colaborador.nome }} </span>
        </div>
      </div>
      <div class="mt-4">
        <div class="info-resumo">
          <p class="mb-1 caption">Conteúdo:</p>
          <span> {{ solicitacao.conteudo }} </span>
        </div>
      </div>
      <div class="mt-4 d-grid">
        <div class="info-resumo mb-4">
          <p class="mb-2 caption">Produto:</p>
          <v-expansion-panels flat>
            <v-expansion-panel>
              <v-expansion-panel-header class="grey lighten-1">{{
                solicitacao.produto.nome
              }}</v-expansion-panel-header>
              <v-expansion-panel-content class="grey lighten-3 pt-4 pb-0">
                <p>Produto: {{ solicitacao.produto.nome }}</p>
                <p>Origem: {{ solicitacao.produto.origem }}</p>
                <p class="mb-0" v-if="linhaProduto.length > 0">
                  Linha: {{ linhaProduto[0].nome }}
                </p>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
        <div class="info-resumo mb-4">
          <p class="mb-2 caption">Representante:</p>
          <v-expansion-panels flat>
            <v-expansion-panel>
              <v-expansion-panel-header class="grey lighten-1">{{
                solicitacao.representante.nome
              }}</v-expansion-panel-header>
              <v-expansion-panel-content class="grey lighten-3 pt-4 pb-0">
                <p>Representante: {{ solicitacao.representante.nome }}</p>
                <p>Email: {{ solicitacao.representante.email }}</p>
                <p class="mb-0">
                  Tel.: {{ solicitacao.representante.telefone }}
                </p>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
        <div
          class="info-resumo mb-4"
          v-if="solicitacao.medicoSolicitante !== null"
        >
          <p class="mb-2 caption">Médico Solicitante:</p>
          <v-expansion-panels flat>
            <v-expansion-panel>
              <v-expansion-panel-header class="grey lighten-1">{{
                solicitacao.medicoSolicitante.nome
              }}</v-expansion-panel-header>
              <v-expansion-panel-content class="grey lighten-3 pt-4 pb-0">
                <p>Solicitante: {{ solicitacao.medicoSolicitante.nome }}</p>
                <p>Email: {{ solicitacao.medicoSolicitante.email }}</p>
                <p v-if="especialidades">
                  Especialidade:
                  {{ especialidades.join(", ") }}
                </p>
                <p class="mb-0">CRM: {{ solicitacao.medicoSolicitante.crm }}</p>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
        <div
          class="info-resumo mb-4"
          v-if="
            (solicitacao.complementoAssunto !== null &&
              solicitacao.complementoAssunto !== '') ||
            solicitacao.concorrente !== null ||
            (solicitacao.materiais !== null &&
              solicitacao.materiais.length > 0) ||
            solicitacao.postagens.length !== 0
          "
        >
          <p class="mb-2 caption">Outras Informações:</p>
          <v-expansion-panels flat>
            <v-expansion-panel>
              <v-expansion-panel-header class="grey lighten-1"
                >Detalhes</v-expansion-panel-header
              >
              <v-expansion-panel-content class="grey lighten-3 pt-4 pb-0">
                <p
                  v-if="
                    solicitacao.complementoAssunto !== null &&
                    solicitacao.complementoAssunto !== '' &&
                    solicitacao.complementoAssunto !== 'null'
                  "
                >
                  Complemento: {{ solicitacao.complementoAssunto }}
                </p>
                <p
                  v-if="
                    solicitacao.concorrente !== null &&
                    solicitacao.concorrente !== ''
                  "
                >
                  Concorrente: {{ solicitacao.concorrente }}
                </p>
                <div
                  v-if="
                    solicitacao.materiais !== null &&
                    solicitacao.materiais !== '' &&
                    solicitacao.materiais.length > 0
                  "
                >
                  <p v-if="solicitacao.materiais[0].titulo">
                    Título: {{ solicitacao.materiais[0].titulo }}
                  </p>
                  <p v-if="solicitacao.materiais[0].autor">
                    Autor: {{ solicitacao.materiais[0].autor }}
                  </p>
                  <p v-if="solicitacao.materiais[0].revista">
                    Revista: {{ solicitacao.materiais[0].revista }}
                  </p>
                  <p v-if="solicitacao.materiais[0].ano">
                    Ano: {{ solicitacao.materiais[0].ano }}
                  </p>
                  <p v-if="solicitacao.materiais[0].ciclo">
                    Ciclo: {{ solicitacao.materiais[0].ciclo }}
                  </p>
                </div>
                <p
                  class="mb-0"
                  v-if="
                    solicitacao.postagens !== null &&
                    solicitacao.postagens.length > 0
                  "
                >
                  Postagens: {{ solicitacao.postagens }}
                </p>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "SolicitacoesResumo",
  props: {
    solicitacao: {
      type: Object,
      default: () => {},
    },
    listLinhas: {
      type: Array,
      default: () => [],
    },
    listMedicosSolicitantes: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    linhaProduto() {
      return this.listLinhas.filter(
        (linha) => this.solicitacao.produto.linhasProdutosId === linha.id
      );
    },
    medico() {
      return this.listMedicosSolicitantes.filter(
        (medico) => this.solicitacao.medicoSolicitante.id === medico.id
      );
    },
    especialidades() {
      if (this.medico.length > 0) {
        return this.medico[0].especialidades.map((item) => item.nome);
      }
      return false;
    },
  },
};
</script>
<style scoped>
.info-resumo {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 6px;
  margin-right: 16px;
  padding: 16px;
}
.d-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
</style>
